<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="filterByServiceType"
			:actions="actions"
		></pui-datatable>
		<modals :modelName="modelName"></modals>
	</div>
</template>

<script>
import TechnicalNauticalServiceActions from './TechnicalNauticalServiceActions.js';
import TechnicalNauticalServiceModals from './TechnicalNauticalServiceModals';

export default {
	name: 'technicalnauticalservice-grid',
	components: { modals: TechnicalNauticalServiceModals },
	data() {
		return {
			modelName: 'technicalnauticalservice',
			modelColumnDefs: {
				servicestatusname: {
					render: (data, type, row) => {
						if (row.statusoutlinecolor && row.statuscolor) {
							return `<label style="color: white; background-color: ${row.statuscolor}; border: 2px solid ${row.statusoutlinecolor}; font-weight: bold; padding: 3px; border-radius: 10px">${data}</label>`;
						} else {
							return data;
						}
					}
				}
			},
			actions: TechnicalNauticalServiceActions.gridactions
		};
	},
	computed: {
		filterByServiceType() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'service_type_id', op: 'ni', data: [1, 2, 3] }]
			};
		}
	}
};
</script>
